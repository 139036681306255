import eSoundsType from './eSoundsType';
import * as PIXI from 'pixi.js';

export default new class SoundsPreloader {
  constructor() {

  }

  addAssetsToBundle(bundleAssets) {
    for (const key in eSoundsType) {
      const value = eSoundsType[key];
      bundleAssets[value] = `assets/sounds/${value}.mp3`;
    }
  }
}
