import './import';
import '../css/index.css'
import 'pixi-spine';
import { createStore } from 'redux';
import reducer from './redux/reducers';
import StoreObserver from './redux/storeObserver';
import Game from './controllers/controllerGame';
import OPWrapperService from './libs/op-wrapper-service';
import wrapperConfig from './wrapperConfig';


export const store = createStore(reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const storeObserver = new StoreObserver(store);

new OPWrapperService(wrapperConfig);

window.Game = new Game();
