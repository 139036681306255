import FlashLib from 'flashlib_onlyplay';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/modals/selectors';
import actions from '../../redux/modals/actions';

export default class ControllerPopup extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);

    this.visible = false;

    this.type = 'base';
  }

  initListener = () => {
    storeObserver.addListenerAsNew(selectors.getModalData(this.type), this.onModalDataChanged.bind(this));
  };

  initBlocker = () => {

    this.blocker = new PIXI.Graphics();
    this.blocker.beginFill('0x000000', 0.6);
    this.blocker.drawRect(this.width/2, this.height/2, 3000 , 2000);
    this.blocker.endFill();

    this.blocker.pivot.set(this.blocker.width/2, this.blocker.height/2);

    this.blocker.interactive = true;
    this.blocker.buttonMode = true;


    this.blocker.addListener('pointertap', this.onCloseClicked)
    this.addChildAt(this.blocker, 0);
  }

  onModalDataChanged(data) {
    this.visible = data.visible;
  };

  onCloseClicked = () => {
    window.OPWrapperService.freeBetsController.updateTotalWin();
    store.dispatch(actions.closeModal({ type: this.type }))
  }
}
