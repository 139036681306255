import FlashLib from 'flashlib_onlyplay';
import { store, storeObserver } from '..';
import selectors from '../redux/game/selectors';
import {goldenGradient} from '../fontStyles';
import { formatCurrency } from '../utils/currency';

export default class ControllerJackpot extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);
        this.visible = window.OPWrapperService.config.jackpotEnabled;

        this.jackpotCoins  = this.getCoinsAndSetPositionCoins();

        this.progress = storeObserver.addListenerAndGetValue(selectors.getJackpotProgress, this.onProgressChanged);
        this.amount = storeObserver.addListenerAndGetValue(selectors.getJackpotAmount, this.onAmountChanged);
        this.setActiveCoins();

        this.text = this.getChildByName('label');
        this.text.style = {...this.text.style, ...goldenGradient};

        this.setAmountText(this.amount);
    }

    onProgressChanged = (value) => {
        this.progress = value;
        this.setActiveCoins();
    }

    setActiveCoins = () => {
        this.jackpotCoins.forEach((coin, i)=> {
            if (i === this.progress - 1) {
                this.setChildIndex(coin, this.children.length-1);
                this.showAnimationPulsation(coin)
                coin.goToFrame(2);
            } else if( i < this.progress ) {
                coin.goToFrame(2);
            } else {
                coin.goToFrame(1);

            }
        })
    }

    getCoinsAndSetPositionCoins = () => {
        let coins = [];

        for (let i = 0; i < 12; i++) {
            const coin = this.getChildByName(`coin_${i}`);
            const halfWidth = coin.width/2,
                halfHeight = coin.height/2;

            coin.pivot.set(halfWidth, halfHeight);
            coin.x += halfWidth*coin.scale.x;
            coin.y += halfHeight*coin.scale.y;

            coins.push(coin);
        }

        return coins;
    }

    setAmountText = (value) => {
        this.amount = value;
        const state = store.getState();
        const currencyInfo = selectors.getCurrencyInfo(state);
        const formatedValue = formatCurrency(this.amount, currencyInfo.decimal )
        this.text.text = `${window.OPWrapperService.localizations.getLocalizedText('jackpot')} ${formatedValue} ${currencyInfo.currency}`;
    }

    onAmountChanged = (value) => {
        this.setAmountText(value);
    }

    showAnimationPulsation = (pixiObj) => {

        const duration = 800;
        const scale = 1.2;

        const start  = Date.now();
        const scaleStart = pixiObj.scale.x;
        const scaleApex = scaleStart*scale;
        const differenceScale = scaleStart - scaleApex;

        const drawFunction = (progress) => {
            let scaleValue = scaleStart;

            if(progress <= 0.25){
                scaleValue = scaleStart - differenceScale * progress / 0.25;

            } else if(progress > 0.25 && progress <= 0.50){
                scaleValue = scaleApex + differenceScale * (progress - 0.25) / 0.25;

            } else if(progress > 0.50 && progress <= 0.75){
                scaleValue = scaleStart - differenceScale * (progress - 0.50) / 0.25;

            } else if(progress > 0.75 && progress <= 1){
                scaleValue = scaleApex + differenceScale * (progress - 0.75) / 0.25;
            }
            pixiObj.scale.set(scaleValue);

        }

        const animation = () => {

            const time =  Date.now() - start;

            if (time <= duration) {
                const progress = time/duration;
                drawFunction(progress);
                requestAnimationFrame(animation);

            } else {
                pixiObj.scale.set(scaleStart);

                return;
            }
        }
        requestAnimationFrame(animation);
    }
}
