import FlashLib from 'flashlib_onlyplay';
import eCellType from './eCellType';

export default class CellHover extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.bush = this.getChildByName(eCellType.ECT_BUSH);
    this.rock = this.getChildByName(eCellType.ECT_ROCK);

    this.rock.interactive = true;
    this.rock.cursor = 'pointer';
    this.rock.hitArea = new PIXI.Polygon([50, 0, 200, 0, 220, 220, 20, 220,]);
    this.rock.on('mouseover', () => {
      this.rock.goToFrame(2);
    });
    this.rock.on('mouseout', () => {
      this.rock.goToFrame(1);
    })

    this.bush.interactive = true;
    this.bush.cursor = 'pointer';

    this.bush.hitArea = new PIXI.Polygon([100, 0, 200, 0, 290, 220, 20, 220,]);
    this.bush.on('mouseover', () => {
      this.bush.goToFrame(2);
    });
    this.bush.on('mouseout', () => {
      this.bush.goToFrame(1);
    })
  }

  init(type) {
    const isBush = type === eCellType.ECT_BUSH;
    this.rock.visible = !isBush;
    this.bush.visible = isBush;
  }
}
