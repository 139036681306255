import SoundManager from '../soundManager/SoundManager';
import eSoundsType from '../preloaders/sounds/eSoundsType';

class ControllerSounds {

  constructor() {
    document.addEventListener('visibilitychange', this.onVisibilityChange);
  }

  onVisibilityChange = () => {
    if (!SoundManager.sound) return;
    if (document.hidden) {
      this.pauseSFX();
    } else {
      this.playSFX();
    }
  };

  playSound(type, progressStartCb = 0) {
    // if (SoundManager.muted) return new Promise((resolve => resolve()));

    return new Promise((resolve, reject) => {
      SoundManager.play(type, 0.7, false, 'default', resolve);
    })
  }

  playSFX = () => {
    if (this.SFXPlaying) return;
    this.SFXPlaying = true;
    SoundManager.play(eSoundsType.EST_SFX, 0.5, true, 'sfx');
  };

  pauseSFX() {
    this.SFXPlaying = false;
    SoundManager.stop(eSoundsType.EST_SFX, 'sfx');
  }
}

export default new ControllerSounds();
