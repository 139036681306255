import ControllerPopup from '../base/controllerPopup';
import eModalType from './eModalType';
import { store} from '../../index';
import gameSelectors from '../../redux/game/selectors';
import { goldenGradient } from '../../fontStyles';

export default class ControllerPopupBigWin extends ControllerPopup {
  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_WIN_BIG;
    this.initListener();

    const state = store.getState();
    const info = gameSelectors.getCurrencyInfo(state);
    this.decimal = info.decimal;


    this.labelTitle = this.getChildByName('textTitle');
    this.labelTitle.style = {...this.labelTitle.style, ...goldenGradient, fontSize: 44};

    this.buttonOk = this.getChildByName('buttonOk').addListener('pointertap', this.onCloseClicked);

    this.winAmountText = new PIXI.BitmapText('0', {
        fontName: 'BmNumbersLuckyTanks',
        fontSize: 100,
        align: 'center',
    });

    this.winAmountText.anchor.set(0.5);
    this.winAmountText.position.x = this.displayData.width/2;
    this.winAmountText.position.y = this.displayData.height/2;
    this.addChild(this.winAmountText);

    this.jackpotEnabled = window.OPWrapperService.config.jackpotEnabled


    this.initBlocker();
  }

  onModalDataChanged(data) {
    super.onModalDataChanged(data);
    const visible = data.visible;
    if(visible) {
      this.setLabelTitle()
      this.showAnimationIncreasingAmount()
    };
  }

  showAnimationIncreasingAmount = () => {
    const duration = 1000;
    const start  = Date.now();
    const winAmount = gameSelectors.getLastWin(store.getState()).win_amount;

    const amountLength = winAmount.toFixed(this.decimal).length
    const amountSize = amountLength < 10 ? 100 : 74;

    this.winAmountText.fontSize = amountSize;

    const drawFunction = (progress) => {
      this.winAmountText.text = (winAmount * progress).toFixed(this.decimal);
    }

    const animation = () => {

        const time =  Date.now() - start;

        if (time <= duration) {
            const progress = time/duration;
            drawFunction(progress);
            requestAnimationFrame(animation);

        } else {
          this.winAmountText.text = winAmount.toFixed(this.decimal);;
            return;

        }
    }
    requestAnimationFrame(animation);
  }

  setLabelTitle = () => {
    const jacpotProgress = gameSelectors.getJackpotProgress(store.getState());
    const key = (jacpotProgress === 12) && this.jackpotEnabled ? 'win_jackpot_title':'win_title';
    this.labelTitle.text = window.OPWrapperService.localizations.getLocalizedText(key);

  }
}
