import FlashLib from 'flashlib_onlyplay';

export default class ControllerPageDot extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.init();
  }
  
  init() {
    this.interactive = true;
    this.cursor = 'pointer';
  }
}
