import { combineReducers } from 'redux';

import gameClover from './game/reducer';
import modals from './modals/reducer';

export default combineReducers({
  gameClover,
  modals,
});


