import actions from '../redux/game/actions';
import actionsModals from '../redux/modals/actions';
import ControllerSounds from '../controllers/controllerSounds';
import { getUrlParams } from '../utils/url';

const URL = '/game_request';

import { store } from '../index';
import eModalType from '../controllers/popups/eModalType';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import gameSelectors from '../redux/game/selectors';

const urlParams = getUrlParams(window.location.search);
const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
if (devEnvironments.some(regexp => regexp.test(window.location.origin))) {
  if (!Object.keys(urlParams).length) {
    getSession();
  }
}

export const requestConfig = async () => {
  const responseInit = await request('get', '/game_init');
  if (!responseInit) return;

  await window.OPWrapperService.init({
    partnerConfig: responseInit.partner_config,
    language: responseInit.lang,
    currencyInfo: {
      currency: responseInit.currency,
      decimals: responseInit.decimals,
    },
  });

  const params = {
    id: 'config',
  };

  const response = await request('post', URL, params).catch(console.error);
  if (response.is_game && response.bet_amount && !response.bets_available.includes(response.bet_amount)) {
    window.OPWrapperService.showMessage('Error', 'Not valid bet', true)
  }

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  store.dispatch(actions.setData(response));
};

export const requestNewGame = async (betIndex) => {
  const params = {
    id: 'new_game',
    betIndex,
  };

  const response = await request('POST', URL, params)
    .catch((error) => {
      if (error.message === 'Token is NULL') {

      } else if (error.code === 6324 || error.code === 6343) {

      }

      throw error;
    });

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setNewGame(response));
};

export const requestFieldOpen = async (index) => {
  const params = {
    id: 'open',
    index,
  };

  const response = await request('POST', URL, params);

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  if (response.is_win) {
    store.dispatch(actions.setDataFieldOpenWin(response));
    if (response.steps === 4) {
      requestTakeGame(store.dispatch);
    } else {
    }
  } else {
    window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    store.dispatch(actions.setDataGameEnd(response));
  }
};

export const requestTakeGame = async () => {
  const params = {
    id: 'collect',
  };

  const response = await request('POST', URL, params);
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setDataGameEnd(response));
  store.dispatch(actions.openOtherCells({ isWin: true }));
  OPWrapperService.ControllerStatistic.win = gameSelectors.getLastWin(store.getState()).win_amount;
  window.OPWrapperService.realityCheck.blockedRealityCheck = false;

  ControllerSounds.playSound(eSoundsType.EST_WIN)
  if (response.steps === 4) {
    store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_BIG }));
  } else {
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
};

async function getSession() {
  try {
    const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=luckytanks&no_redirect&no_op_launcher', {
      method: 'GET',
    });
    const response = await raw.json();
    if (response.url) {
      const searchParams = response.url.split('?')[1];
      window.location = `${window.location.origin}?${searchParams}`;
    }
  } catch (e) {
    console.error(e);
  }
}

let waitingForResponse = false;
const request = async (method, url, params) => {
  if (waitingForResponse) {
    throw new Error('Already waiting for response.')
  }

  if (!navigator.onLine) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
    throw new Error('No Internet connection.')
  }

  waitingForResponse = true;

  url = `https://${urlParams.api + url}/?sid=${urlParams.sid}&gid=${urlParams.gid}`;

  const options = { method };

  if (params) {
    params.ver = '1.1';

    if (method.toUpperCase() === 'POST') {
      options.body = JSON.stringify(params);
    } else {
      url += `&req=${JSON.stringify(params)}`;
    }
  }

  let response = await fetch(url, options);

  if (response.status === 500) {
    throw new Error('Server Down');
  }

  response = await response.json()
    .catch((error) => {
      console.error(error);
      console.error('Response', response);
      throw error;
    });

  waitingForResponse = false;

  if (!response.success) {
    //9001 - insufficient balance
    window.OPWrapperService.showError(response.code, response.message);
    throw response;
  }
  return response;
};
