export default {
  EST_CLICK: 'click',
  EST_LOSE: 'lose',
  EST_SHOT: 'shot',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
  EST_HAVE_SHOT: 'haveShot',
  EST_DEFEATED: 'defeated',
  EST_EXPLOSION: 'explosion',
}
