import { storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import { formatCurrency } from '../utils/currency';

export default class ControllerBalanceView {

  constructor(main) {
    let field = main.getChildByName('field');
    this.labelBalance = field.getChildByName('labelBalance');

    this.balance = storeObserver.addListenerAndGetValue(selectors.getBalance, this.onBalanceChanged);
    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.onCurrencyInfoChanged);

    this.onBalanceChanged(this.balance);
  }

  onBalanceChanged = (value) => {
    this.balance = value;
    const formatedValue = formatCurrency(value, this.currencyInfo.decimal)
    this.labelBalance.text = `${formatedValue} ${this.currencyInfo.currency}`;
  };

  onCurrencyInfoChanged = (value) => {
    this.currencyInfo = value;
    this.onBalanceChanged(this.balance);
  };
}
