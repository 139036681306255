import gameClover from './reducer';

const getBalance = (state) => state.gameClover.balance;
const getBetAmount = (state) => state.gameClover.betAmount;
const getBetIndex = (state) => state.gameClover.bets.indexOf(state.gameClover.betAmount);
const getGameState = (state) => state.gameClover.gameState;
const getCurrencyType = (state) => state.gameClover.currency;
const getStatistic = (state) => state.gameClover.statistic;
const getActivities = (state) => state.gameClover.activity;
const getLastWin = (state) => state.gameClover.activity.myBets[0];
const getWinAmount = (state) => state.gameClover.betAmount * state.gameClover.rulesCoefs[state.gameClover.steps - 1];
const getProvablyFair = (state) => state.gameClover.provablyFair;
const getCurrencyInfo = (state) => state.gameClover.currencyInfo;
const getTicketId = (state) => state.gameClover.ticketId;
const getBetMinMax = (state) => ({ min: state.gameClover.betMin, max: state.gameClover.betMax });
const getSteps = (state) => state.gameClover.steps;
const getIsWin = (state) => state.gameClover.isWin;
const getJackpotProgress = (state) => state.gameClover.jackpotProgress;
const getJackpotAmount = (state) => state.gameClover.jackpotAmount;
const getGameRestarted = (state) => state.gameClover.gameRestarted;




const getFieldCellInfo = (index) => (state) => state.gameClover.fieldInfo[index];

export default {
  getBalance,
  getBetAmount,
  getBetIndex,
  getGameState,
  getCurrencyType,
  getFieldCellInfo,
  getStatistic,
  getActivities,
  getWinAmount,
  getLastWin,
  getCurrencyInfo,
  getProvablyFair,
  getTicketId,
  getBetMinMax,
  getSteps,
  getIsWin,
  getJackpotProgress,
  getJackpotAmount,
  getGameRestarted
}
