export default {
  ECA_EMPTY_ROCK: 'DEW__rock_empty_explosion',
  ECA_IDLE_ROCK: 'DEW__rock_idle',
  ECA_DESTROY_START_ROCK: 'DEW_enemy_rock_destroyed_start',
  ECA_DESTROY_IDLE_ROCK: 'DEW_enemy_rock_destroyed_idle',

  ECA_EMPTY_BUSH: 'DEW_bush_empty_explosion',
  ECA_IDLE_BUSH: 'DEW_bush_idle',
  ECA_DESTROY_START_BUSH: 'DEW_bush_enemy_explosion',
  ECA_DESTROY_IDLE_BUSH: 'DEW_bush_enemy_destroyed_idle',

  ECA_KILLED: 'DEW_enemy_killed_player',
  ECA_IDLE_SCENE: 'DEW_idle_scene',
  ECA_TANK_DESTROYED_START: 'DEW_player_tank_destroyed_start',
  ECA_TANK_DESTROYED_IDLE: 'DEW_player_tank_destroyed_idle',
  ECA_TANK_IDLE: 'DEW_tank_idle',
  ECA_TANK_SHOT: 'DEW_tank_shot',
}
