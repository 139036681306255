import { store, storeObserver } from '../../index';
import modalActions from '../../redux/modals/actions';
import eModalType from '../../controllers/popups/eModalType';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';
import SoundManager from '../../soundManager/SoundManager';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));

    SoundManager.onSoundSettingsCahnged.add(this.audioToggle.bind(this));
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  setAudio(value) {
    if (value) {
      SoundManager.mainUnmute();
    } else {
      SoundManager.mainMute();
    }
  }

  showHelp() {

  }

  togglePaytable() {
    const visible = store.getState().modals[eModalType.EMT_RULES].visible;
    if (visible) {
      store.dispatch(modalActions.closeModal({ type: eModalType.EMT_RULES }));
    } else {
      store.dispatch(modalActions.showModal({ type: eModalType.EMT_RULES }));
    }
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    const audioState = !SoundManager.muted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
    this.stakeUpdate();
  }

  audioToggle() {
    const audioState = !SoundManager.muted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
  }

  stakeUpdate() {
    const state = store.getState();
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, selectors.getBetAmount(state));
  }

  stateUpdated() {
    const state = store.getState();
    const gameState = selectors.getGameState(state);
    if (gameState === eGameState.EGS_CAN_CANCEL ) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
    }

    if (gameState === eGameState.EGS_READY_TO_PLAY) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
    }

    if (gameState === eGameState.EGS_CAN_STOP) {
    }
  }

  closed() {

  }

  errorMessage(data) {
    const message = {
      errorCode: '8501',
      errorMessage: data.params.message
    };
    //EveryMatrix.errorMessage(message);
  }
}
