export default {
  CANVAS_WIDTH_LANDSCAPE: 2340,
  CANVAS_HEIGHT_LANDSCAPE: 1080,

  CANVAS_WIDTH_PORTRAIT: 2340,
  CANVAS_HEIGHT_PORTRAIT: 1080,

  SAFE_WIDTH_LANDSCAPE: 800,
  SAFE_HEIGHT_LANDSCAPE: 1080,

  SAFE_WIDTH_PORTRAIT: 800,
  SAFE_HEIGHT_PORTRAIT: 1080
}
