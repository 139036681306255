export const greenStroke = {
  stroke: '#3c6e24',
  strokeThickness: 8,
};

export const greenStrokeShadow = {
  stroke: '#3c6e24',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 1,
  dropShadowColor: '#1d3d0d',
  dropShadowDistance: 10,
};

export const yellowStrokeGradient = {
  letterSpacing: 10,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#4e0600',
  strokeThickness: 10,
  fill: [
    "#fab406",
    "#f7f6af",
    "#fab406",
    "#fffb00"
  ]
};

export const goldenGradient = {
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 8,
  dropShadowColor: "#1d0a01",
  dropShadowDistance: 0,
  fill: [
      "#fffed6",
      "#fdeb52",
      "#ad5e26",
      "#fdeb52"
  ],
  fillGradientStops: [
      0.3,
      0.55,
      0.75,
      0.82
  ],
  fontSize: 32,
  fontWeight: 600,
  letterSpacing: 2,
  lineJoin: "round",
  stroke: "#1d0a01",
  strokeThickness: 1
};
