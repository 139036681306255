import ControllerSounds from './controllerSounds';

export default class ControllerStartScreen {
  constructor(container) {
    this.container = container;
    this.containerHeight = this.container.height;
    this.buttonStart = container.getChildByName('buttonStart');
    this.buttonStart.getChildByName('labelLong').visible = false;
    const buttonLabel = this.buttonStart.getChildByName('label');
    buttonLabel.text = window.OPWrapperService.localizations.getLocalizedText('welcome_start');

    this.bg = container.getChildAt(0);
    this.bg.anchor.set(0.5, 0.5);
    this.bg.x = this.bg.width / 2;
    this.bg.y = this.bg.height / 2;

    this.buttonStart.on('pointertap', this.onButtonStartClicked);
    // this.container.visible = false;

    this.logo = container.getChildAt(4);
    this.winView = container.getChildAt(3);
    this.jackpotIcon = container.getChildAt(2);
    this.jackpotDesc = container.getChildByName('jackpot_desc');

    const names = ['logo', 'winView', 'jackpotIcon', 'jackpotDesc', 'buttonStart'];
    this.names = names;
    this.savedPositions = {};
    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      this.savedPositions[name] = { x: this[name].x, y: this[name].y };
    }

    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onResize,
      this
    );
    console.log(this.savedPositions)
    this.container.interactive = true;
    if (!window.OPWrapperService.config.jackpotEnabled) {
      this.jackpotIcon.visible = false;
      this.jackpotDesc.visible = false;
    }
  }

  onButtonStartClicked = () => {
    this.container.visible = false;
    ControllerSounds.playSFX();
    window.OPWrapperService.Fullscreen.showButtonIfAndroid()
    window.OPWrapperService.freeBetsController.show();
  };

  onResize = (data) => {
    let scale = 0;
    if (data.canvasHeight > this.containerHeight * data.scale) {
      scale = data.canvasHeight / (this.containerHeight * data.scale);
    } else {
      scale = 1;
    }
    this.bg.scale.set(scale);

    this._reset();
    this._replaceItems(data);
  }

  _reset() {
    for (let i = 0; i < this.names.length; i++) {
      const name = this.names[i];
      if(this[name].anchor) this[name].anchor.set(0, 0);
      this[name].pivot.set(0, 0);
      this[name].scale.set(1);
      this[name].x = this.savedPositions[name].x;
      this[name].y = this.savedPositions[name].y;
    }
  }

  _replaceItems(data) {
    if (data.canvasWidth / 2 > this.jackpotDesc.x + this.jackpotDesc.width) return;

    const centerX = (-data.stageX + data.canvasWidth / 2) / data.scale;
    const margin =  20;

    this.logo.anchor.set(0.5, 0);
    this.logo.x = centerX
    this.logo.y = -data.stageY / data.scale + margin;

    this.winView.anchor.set(0.5, 0);
    this.winView.x = centerX
    this.winView.y = this.logo.y + this.logo.height + margin;

    this.jackpotIcon.anchor.set(0.5, 0);
    this.jackpotIcon.x = centerX
    this.jackpotIcon.y = this.winView.y + this.winView.height + margin;

    this.jackpotDesc.pivot.set(this.jackpotDesc.width / this.jackpotDesc.scale.x / 2, 0);
    this.jackpotDesc.x = centerX
    this.jackpotDesc.y = this.jackpotIcon.y + this.jackpotIcon.height + margin;

    this.buttonStart.pivot.set(this.buttonStart.width / 2, 0);
    this.buttonStart.x = centerX
    this.buttonStart.y = this.jackpotDesc.y + this.jackpotDesc.height * this.jackpotDesc.scale.x;

    const gameHeight = data.canvasHeight / data.scale;
    const allElementsHeight = this.buttonStart.y + this.buttonStart.height + margin + data.stageY / data.scale;

    const offsetForCentring = (gameHeight - allElementsHeight) / 2;
    if (offsetForCentring > 0) this.names.forEach(name => this[name].y += offsetForCentring)

    // console.log('stageY', data.stageY)
    // console.log(this.buttonStart.y + this.buttonStart.height);
    // console.log(data.canvasHeight / data.scale);
    // console.log('scale', data.scale);

    if(allElementsHeight > gameHeight) {
      this.logo.scale.set(this.logo.scale.x - 0.01);
      this.winView.scale.set(this.winView.scale.x - 0.01);
      this.jackpotIcon.scale.set(this.jackpotIcon.scale.x - 0.01);
      this.jackpotDesc.scale.set(this.jackpotDesc.scale.x - 0.01);

      this._replaceItems(data);
    }
  }
}
