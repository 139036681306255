import FlashLib from 'flashlib_onlyplay';
import ControllerButton from './controllers/base/controllerButton';
import ControllerFieldCell from './controllers/cell/controllerFieldCell';
import ControllerPopupRules from './controllers/popups/popupRules';
import ControllerPopupWin from './controllers/popups/popupWin';
import ControllerPopupBigWin from './controllers/popups/popupBigWin';
import CellHover from './controllers/cell/cellHover';
import ControllerButtonArrow from './controllers/popups/rules/controllerButtonArrow';
import ControllerPageDot from './controllers/popups/rules/controllerPageDot';
import ControllerJackpot from './controllers/controllerJackpot';

FlashLib.registerClass('Button', ControllerButton);
FlashLib.registerClass('FieldCell', ControllerFieldCell);
FlashLib.registerClass('ControllerPopupRules', ControllerPopupRules);
FlashLib.registerClass('ControllerPopupBigWin', ControllerPopupBigWin);
FlashLib.registerClass('ControllerPopupWin', ControllerPopupWin);
FlashLib.registerClass('CellHover', CellHover);
FlashLib.registerClass('ControllerButtonArrow', ControllerButtonArrow);
FlashLib.registerClass('ControllerPageDot', ControllerPageDot);
FlashLib.registerClass('ControllerJackpot', ControllerJackpot);

