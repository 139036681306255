import FlashLib from 'flashlib_onlyplay';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/game/selectors';
import { requestFieldOpen } from '../../api/rest';
import { eCellState, eGameState } from '../../redux/game/reducer';
import { yellowStrokeGradient } from '../../fontStyles';
import ControllerSounds from '../controllerSounds';
import eSoundsType from '../../preloaders/sounds/eSoundsType';
import AnimationsLoader from '../../preloaders/animations/animationsLoader';
import eAnimationType from '../../preloaders/animations/eAnimationType';
import actions from '../../redux/game/actions';
import eCellType from './eCellType';
import eCellAnimation from './eCellAnimation';
import Event from '../../utils/event';
import TweenMax from 'gsap';
import { MAIN_TANK_INDEX } from '../../constants';

const OFFSETS = {
  [eCellType.ECT_BUSH]: {
    x: -8,
    y: 169,
  },
  [eCellType.ECT_ROCK]: {
    x: 4,
    y: 76
  }
}

export default class ControllerFieldCell extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    // this.interactive = true;
    this.possibleOpenAll = false;
    this.clicked = false;
    this.onCompleteAnim = new Event();

    // this.goToFrame(3);
    this.index = this.name.replace('cell_', '');

    this.type = this.index % 2 === 0 ? eCellType.ECT_BUSH : eCellType.ECT_ROCK;//Math.random() > 0.5 ? eCellType.ECT_ROCK : eCellType.ECT_BUSH;
    this.TYPE = this.type.toUpperCase();

    this.cellHover = this.children[0];
    this.cellHover.init(this.type);
    this.cellHover.interactive = true;
    this.cellHover.on('pointertap', this.onCellClicked, this);

    this.coefText = this.getChildByName('coef');
    this.coefText.visible = false;
    this.coefText.style = {
      ...this.coefText.style,
      ...yellowStrokeGradient,
      fontSize: 144
    };

    this.initCellAnimation();
    this.initSpinner();

    let state = '';
    this.cachedData = {};
    state = storeObserver.addListenerAsNewAndGetValue(selectors.getFieldCellInfo(this.index), this.onStateChanged);
    this.onStateChanged(state);
  }

  initSpinner() {
    this.spinner = AnimationsLoader.createAnimation(eAnimationType.EAT_SPINNER);
    this.spinner.x = this.displayData.width / 2 / this.scale.x;
    this.spinner.y = this.displayData.height / 2 / this.scale.y;
    this.spinner.scale.set(this.spinner.scale.x / 2);
    this.spinner.state.setAnimation(0, 'spinner', true);
    this.spinner.visible = false;
    this.addChild(this.spinner);
  }

  initCellAnimation() {
    this.animation = AnimationsLoader.createAnimation(eAnimationType.EAT_CELL);
    // this.animation.pivot.set(this.animation.width / 2, this.animation.height / 2);
    // this.animation.scale.set(this.scaleX);
    const offsets = OFFSETS[this.type];
    this.animation.x = this.displayData.width / 2 / this.scale.x + offsets.x;
    this.animation.y = this.displayData.height / 2 / this.scale.y + offsets.y;
    this.addChildAt(this.animation, 0);
    this.promises = {};
    this.animation.state.addListener({
      complete: this.onAnimationComplete
    });
  }

  onAnimationComplete = (data) => {
    const name = data.animation.name;
    if (name === eCellAnimation[`ECA_EMPTY_${this.TYPE}`] && this.possibleOpenAll) {
      store.dispatch(actions.openOtherCells({ isWin: false }));
      this.possibleOpenAll = false;
    }
    this.onCompleteAnim.call(name, this.index);
    // this.tryShowText();

    if (this.promises[name]) {
      this.promises[name].resolve();
      delete this.promises[name];
    }
  };

  showAnimation(type) {
    return new Promise((resolve) => {
      this.animation.state.setAnimation(0, type, false);
      this.promises[type] = { resolve };
    })
  }

  showSpinner = () => {
    if (this.spinner)
      this.spinner.visible = true;
  };

  hideSpinner = () => {
    if (this.spinner)
      this.spinner.visible = false;
  };

  onCellClicked = async () => {
    const state = store.getState();
    if (selectors.getGameState(state) !== eGameState.EGS_READY_TO_PLAY && this.cachedData.isClosed && !this.clicked) {
      this.clicked = true;
      this.mainTankShot();
      this.showSpinner();
      this.possibleOpenAll = true;
      requestFieldOpen(this.index)
        .finally(() => {
          this.hideSpinner()
          this.clicked = false;
        });
    }
  };

  onStateChanged = (data) => {
    const gameRestarted = selectors.getGameRestarted(store.getState());
    if (data.isClosed) {
      // this.animation.update(0);
      // this.animation.skeleton.setToSetupPose();
      // this.animation.state.tracks = [];
      this.possibleOpenAll = false;
      const animation = this.animation.state.setAnimation(0, eCellAnimation[`ECA_IDLE_${this.TYPE}`], true);
      animation.timeScale = 0;
      this.cellHover.visible = true;
      this.animation.y = this.displayData.height / 2 / this.scale.y + OFFSETS[this.type].y;
    } else if (data.isClosed !== this.cachedData.isClosed || data.state !== this.cachedData.state) {
      this.cellHover.visible = false;
      if (data.state === eCellState.ECS_BOMB) {
        this.showAnimation(eCellAnimation[`ECA_EMPTY_${this.TYPE}`]);
      } else {
        if (data.isPath && !gameRestarted) {
          const type = Math.random() > 0.5 ? eSoundsType.EST_HAVE_SHOT : eSoundsType.EST_DEFEATED;
          ControllerSounds.playSound(eSoundsType.EST_EXPLOSION);
          setTimeout(() => ControllerSounds.playSound(type), 100);
          this.showText(data);
          this.animation.state.setAnimation(0, eCellAnimation[`ECA_DESTROY_START_${this.TYPE}`], false);
        }
        if (data.isPath) {
          this.animation.state.addAnimation(0, eCellAnimation[`ECA_DESTROY_IDLE_${this.TYPE}`], true, 0);
        } else {
          if (this.type === eCellType.ECT_BUSH) this.animation.y = 214;
          this.animation.state.setAnimation(0, eCellAnimation.ECA_KILLED, false);
        }
      }
    }

    if (!data.isPath && !data.isClosed) {
      // this.alpha = 0.5;
    } else {
      this.alpha = 1;
    }

    if (data.isClosed) {
      this.hideText();
    }
    this.cachedData = data;
  };

  showText(data) {
    if (data) {
      this.coefText.text = `×${data.coef}`;
      this.showCoefAnimation(this.coefText);
    }

  }

  hideText() {
    this.coefText.text = ' ';
  }

  showCoefAnimation = (pixiObj) => {
    pixiObj.visible = true;
    const scaleStart = pixiObj.scale.x;
    const alphaStart = pixiObj.alpha;
    const startY = pixiObj.y;

    TweenMax.to(pixiObj, 3, { y: `-=${200}`, alpha: 0 });
    TweenMax.to(pixiObj.scale, 3, {
      x: 0.8,
      y: 0.8,
      onComplete: () => {
        TweenMax.killTweensOf(pixiObj);
        pixiObj.visible = false;
        pixiObj.scale.set(scaleStart);
        pixiObj.alpha = alphaStart;
        pixiObj.y = startY;
      }
    });
  }

  mainTankShot = () => {
    if (!this.mainTankAnimation) {
      this.mainTankAnimation = this.parent.children[MAIN_TANK_INDEX];
    }
    ControllerSounds.playSound(eSoundsType.EST_SHOT);
    this.mainTankAnimation.state.setAnimation(0, eCellAnimation.ECA_TANK_SHOT, false);
  }
}
