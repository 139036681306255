import Event from '../utils/event';

export default class StoreObserver {
  constructor(store) {
    this.store = store;
    this.debugEnabled = false;
    this.store.subscribe(this.onStoreChanged);
    this.cachedState = JSON.parse(JSON.stringify(store.getState()));
    this.selectors = {};
  }

  onStoreChanged = () => {
    for (let key in this.selectors) {
      let item = this.selectors[key];
      const prevValue = item.check(this.cachedState);
      const newValue = item.check(this.store.getState());
      if (this.debugEnabled)
        console.log(`Store observer\n` +
          `prevValue: ${prevValue}\n` +
          `newValue: ${newValue}\n` +
          `Not same link: ${prevValue !== newValue}\n` +
          `Not same content: ${(JSON.stringify(prevValue) !== JSON.stringify(newValue))}\n` +
          `Events count: ${item.events._listeners.length}`);
      if ((typeof(newValue) !== 'object' && prevValue !== newValue) || (JSON.stringify(prevValue) !== JSON.stringify(newValue))) {
        item.events.call(newValue);
      } 
    }
    this.cachedState = JSON.parse(JSON.stringify(this.store.getState()));
  };

  addListener = (check, callback) => {
    if (!this.selectors[check]) {
      this.selectors[check] = { check: check, events: new Event() };
    }
    this.selectors[check].events.add(callback);
  };

  addListenerAsNew = (check, callback) => {
    let newKey = check.toString();
    if (this.selectors[check]) {
      newKey += Math.random();
    }
    this.selectors[newKey] = { check: check, events: new Event() };
    this.selectors[newKey].events.add(callback);
  };

  addListenerAsNewAndGetValue = (check, callback) => {
    this.addListenerAsNew(check, callback);

    return check(this.store.getState());
  };

  addListenerAndGetValue = (check, callback) => {
    this.addListener(check, callback);

    return check(this.store.getState());
  };

  removeListener = (check, callback) => {
    if (!this.selectors[check]) {
      return;
    }

    this.selectors[check].events.remove(callback);
  }
}
