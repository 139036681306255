import ControllerButton from '../../base/controllerButton';

export default class ControllerButtonArrow extends ControllerButton {
  constructor(data, displayData) {
    super(data, displayData);

    this.symbol = this.children[this.children.length - 1];

    const scale = this.name === 'right' ? -1 : 1;
    this.symbol.anchor.set(0.5, 0.5);
    this.symbol.x = this.width / 2;
    this.symbol.y = this.height / 2 - 4;
    this.symbol.scale.set(scale, 1);
  }
}
