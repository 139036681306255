import selectors from '../redux/game/selectors';
import { store, storeObserver } from '../index';
import actions from '../redux/game/actions';
import { eGameState } from '../redux/game/reducer';

export default class ControllerBetSelector {

  constructor(main) {
    let field = main.getChildByName('field');
    this.labelBet = field.getChildByName('labelBet');

    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.currencyInfoChanged);
    const betAmount = storeObserver.addListenerAndGetValue(selectors.getBetAmount, this.onBetAmountChanged);
    this.onBetAmountChanged(betAmount);

    this.buttonMin = field.getChildByName('buttonMin');
    this.buttonMin.addListener('pointertap', this.onMinClicked);
    this.buttonMinus = field.getChildByName('buttonMinus');
    this.buttonMinus.addListener('pointertap', this.onMinusClicked);
    this.buttonPlus = field.getChildByName('buttonPlus');
    this.buttonPlus.addListener('pointertap', this.onPlusClicked);
    this.buttonMax = field.getChildByName('buttonMax');
    this.buttonMax.addListener('pointertap', this.onMaxClicked);

    this.dispatch = store.dispatch;

    this.gameState =  storeObserver.addListenerAndGetValue(selectors.getGameState, this.onGameStateChanged);
    this.onGameStateChanged(this.gameState);
  }

  setButtonsEnable = (value) => {
    if (value) {
      this.buttonMin.enable();
      this.buttonMinus.enable();
      this.buttonPlus.enable();
      this.buttonMax.enable();
    } else {
      this.buttonMin.disable();
      this.buttonMinus.disable();
      this.buttonPlus.disable();
      this.buttonMax.disable();
    }
  };

  onGameStateChanged = (gameState) => {
    this.setButtonsEnable(
      gameState === eGameState.EGS_READY_TO_PLAY
      && !window.OPWrapperService.freeBetsController.isActive
      && !window.OPWrapperService.freeBetsController.isFirstFreeBet
    );
  };

  onBetAmountChanged = (value) => {
    this.labelBet.text = value.toFixed(this.currencyInfo.decimal);
  };

  currencyInfoChanged = (value) => {
    this.currencyInfo = value;
  };

  onMinClicked = () => {
    this.dispatch(actions.setMinBet());
  };

  onMaxClicked = () => {
    this.dispatch(actions.setMaxBet());
  };

  onPlusClicked = () => {
    this.dispatch(actions.incrementBet());
  };

  onMinusClicked = () => {
    this.dispatch(actions.decrementBet());
  };

  // let intervalPlusPressed = useRef(null);
  onPlusPressed = () => {
    intervalPlusPressed.current = setInterval(() => dispatch(actions.incrementBet()), 100);
  };

  onPlusUnpressed = () => {
    clearInterval(intervalPlusPressed.current);
  };

  // let intervalMinusPressed = useRef(null);
  onMinusPressed = () => {
    intervalMinusPressed.current = setInterval(() => dispatch(actions.decrementBet()), 100);
  };

  onMinusUnpressed = () => {
    clearInterval(intervalMinusPressed.current);
  };
}
