import '../../scss/styles.scss'
import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib_onlyplay';
import { requestConfig } from '../api/rest';
import ControllerButtonPlay from './buttonPlay';
import WebFont from 'webfontloader';
import ControllerBalanceView from './controllerBalanceView';
import ControllerBetSelector from './controllerBetSelector';
import AnimationsLoader from '../preloaders/animations/animationsLoader';
import ControllerStartScreen from './controllerStartScreen';
import ControllerTextField from './textField/ControllerTextField';

// import assets from '../../assets/exported/game/FlashLibAssets.json';
import meta from '../../assets/exported/game/FlashLib.json';
import ControllerScene from './controllerScene';
import SoundsPreloader from '../preloaders/sounds/SoundsPreloader';
import SoundManager from '../soundManager/SoundManager';
import EMAdapter from '../platforms/everyMatrix/adapter';
import Config from './Config';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.animationsLoaded = false;

    FlashLib.TextField = ControllerTextField;
    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    this.start();
  }

  start() {
    this.container = document.getElementById('container');
    this.gameSize = {
      width: 2340,
      height: 1080
    };
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0xF6D5C9,
      // legacy: true,
      resolution: 1,//this.isRetinaDisplay() ? 1 : 0.5,
      // autoResize: true
    };
    this.app = new PIXI.Application(this.applicationOptions);
    this.app.view.id = 'gameCanvas';

    this.container.appendChild(this.app.view);

    this.scaleData = {
      app: this.app,
      scaleRatio: 2,
      gameSize: this.gameSize
    };

    this.loadAssets();
    this.loadFonts();

    requestConfig()
      .then(() => {
        console.log('Config received');
        this.configReceived = true;
        this.tryInitGame();
      });
      EMAdapter.init();
  }

  loadAssets() {
    const assets = 'flashlib/game/AssetsCombined.json'
    const bundleAssets = {
      FlashLib: meta,
      FlashLibAssets: assets,
      BmNumbersLuckyTanks: 'assets/fonts/bmFonts/BmNumbersLuckyTanks/BmNumbersLuckyTanks.xml',
    };

    AnimationsLoader.addAssetsToBundle(bundleAssets);
    SoundsPreloader.addAssetsToBundle(bundleAssets);

    PIXI.Assets.addBundle('assets', bundleAssets);

    PIXI.Assets.loadBundle('assets', this.onAssetsLoadingUpdate.bind(this))
      .then(this.onAssetsLoadingComplete.bind(this))
      .catch(this.onLoadingError.bind(this));
  }

  onAssetsLoadingUpdate(progress) {
    progress *= 100;
    OPPreloader.setProgress(progress);
    EMAdapter.loadProgress(progress);
  }

  onAssetsLoadingComplete() {
    console.log('Assets loaded');
    this.assetsLoaded = true;
    this.tryInitGame();
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: ['Phosphate Pro solid', 'Ubuntu bold', 'Ubuntu'],
      },
      fontinactive: console.error,
      fontloading: console.log,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }

  tryInitGame() {
    if (this.fontsLoaded &&
      this.assetsLoaded &&
      this.configReceived) {
      window.OPPreloader.hide();
      this.initGame();
    }
  }

  initGame() {
    EMAdapter.loadCompleted()
    SoundManager.init();

    // this.app.renderer.blendModes[PIXI.BLEND_MODES.ADD] = [this.app.renderer.gl.ONE, this.app.renderer.gl.ONE];
    let main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    let field = main.getChildByName('field');
    this.app.stage.addChild(main);

    new ControllerStartScreen(main.getChildByName('startScreen'));
    new ControllerButtonPlay(main);
    new ControllerBalanceView(main);
    new ControllerBetSelector(main);
    new ControllerScene(field);

    field.getChildByName('buttonMenu').addListener('pointertap', () => {
      OPWrapperService.toggleSidebar();
    });
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config)
  }

}
