import en from '../assets/i18n/en';
import enSocial from '../assets/i18n/en-social';
import actionsModals from './redux/modals/actions';
import eModalType from './controllers/popups/eModalType';
import { store } from './index';

export default {
  buttons: [
    {
      type: 'rules',
      styles: ['rules'],
      onClick: () => store.dispatch(actionsModals.showModal({ type: eModalType.EMT_RULES }))
    }
  ],
  bundle: 'luckytanks',
  localizationBundle: 'luckytanks',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
