import { store } from '../..';
import selectors from '../../redux/game/selectors';
import ControllerPopup from '../base/controllerPopup';
import eModalType from './eModalType';

export default class ControllerPopupRules extends ControllerPopup {

  constructor(data, displayData) {
    super(data, displayData);

    this.frames = 2;
    this.currentFrame = 1;

    this.type = eModalType.EMT_RULES;
    this.initListener();

    this.getChildByName('buttonClose').addListener('pointertap', this.onCloseClicked);
    // this.visible = true;

    this.buttonLeft = this.getChildByName('left');
    this.buttonRight = this.getChildByName('right');

    this.buttonLeft.addListener('pointertap', this.onButtonClicked('left'));
    this.buttonRight.addListener('pointertap', this.onButtonClicked('right'));

    this.dots = this.getDots();
    this.setActiveDot();

    this.dots.forEach((dot, i) => dot.addListener('pointertap',() => this.onDotClicked(i)));

    this.initBlocker();

    this.getChildByName('bg').interactive = true;

  }

  onModalDataChanged(data) {
    super.onModalDataChanged(data);
    const visible = data.visible;
    if(visible && this.currentFrame === 1) {
      this.setMinMaxBet()
    };
  }

  setMinMaxBet = () => {
    const minBetLabel = this.getChildByName('minBet');
    const maxBetLabel = this.getChildByName('maxBet');
    const state = store.getState();
    const betMinMax = selectors.getBetMinMax(state);
    const currencyInfo = selectors.getCurrencyInfo(state);
    minBetLabel.text = `${betMinMax.min.toFixed(currencyInfo.decimal)} ${currencyInfo.currency}`;
    maxBetLabel.text = `${betMinMax.max.toFixed(currencyInfo.decimal)} ${currencyInfo.currency}`;

  }

  onButtonClicked = (type) => {
    return () => {
      let nextFrame;
      if(type === 'right') {
        nextFrame  = this.currentFrame < this.frames ? this.currentFrame + 1 : 1;
      } else {
        nextFrame = this.currentFrame > 1 ? this.currentFrame - 1: this.frames;
      }
      this.currentFrame = nextFrame;
      this.setActiveDot()
      this.goToFrame(nextFrame);
      if (this.currentFrame === 1) this.setMinMaxBet();
    }
  }

  getDots = () => {
    let dots = [];

    for (let i = 0; i < 2; i++) {
      dots.push(this.getChildByName(`page_dot_${i}`))
    }

    return dots;
  }

  setActiveDot = () => {
    this.dots.forEach((dot, i)=>{

      if(i === this.currentFrame - 1){
        dot.goToFrame(2);
      } else {
        dot.goToFrame(1);
      }

    })
  }

  onDotClicked = (i)=> {
    this.currentFrame  = i + 1;
    this.goToFrame(this.currentFrame);
    if (this.currentFrame === 1) this.setMinMaxBet();
    this.setActiveDot()
  }


}
